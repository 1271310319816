import { Box, Typography } from '@material-ui/core'
import AvailabilityCheckInput from 'components/AvailabilityCheckInput'
import VZFInput from 'components/VZFInput'
import ViewWrapper from 'components/ViewWrapper'
import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { AvailabilityCheckField } from 'store/AvailabilityCheck/AvailabilityCheck.actions'
import { ViewType } from 'store/GeneralState/GeneralState.reducer'
import { getTestIDOnViewType } from 'utils/TestIDs'
import useURLParams from 'utils/URLParamsContex'
import { getRoleAwareTranslationKey, useUserInfo } from 'utils/UserInfoContext'
import { useAvailabilityCheckReducer } from './useAvailabilityCheckReducer'

const AvailabilityCheck = (): JSX.Element => {
    const availabilitySubmitRef = useRef<HTMLButtonElement>(null)
    const {
        availableCities,
        availableHouseNumbers,
        availableStreets,
        availableAddition,
        availableDistricts,
        cities,
        currentView,
        customizeJsData,
        focusedFieldId,
        houseNumbers,
        streets,
        districts,
        additions,
        zip,
        vzf,
        vzfNoResult,
        vzfLoading,
        selectedCity,
        setLoadState,
        selectedHouseNumber,
        selectedStreet,
        selectedDistrict,
        selectedAddition,
        setSelectedCity,
        setSelectedHouseNumber,
        setSelectedStreet,
        setSelectedDistrict,
        setSelectedAddition,
        setCities,
        setHouseNumbers,
        setStreets,
        setFocusedField,
        setZip,
        setDistricts,
        setAdditions,
        onChangeVZF,
        onClickCheckVZF,
        getAvailableCities,
        getAvailableHouseNumbers,
        getAvailableStreets,
        getAvailableAdditions,
        getAvailableDistricts,
    } = useAvailabilityCheckReducer()

    const { t } = useTranslation()

    const { vzf: paramsVZF, B2B } = useURLParams()

    const enableAddressAddition = customizeJsData?.globalConfiguration.enableAddressAddition ?? false
    const enableAddressDistrict = customizeJsData?.globalConfiguration.enableAddressDistrict ?? false

    useEffect(() => {
        if (paramsVZF && !B2B) onChangeVZF(paramsVZF)
    }, [paramsVZF])

    useEffect(() => {
        if (availableCities.data) {
            setCities(availableCities.data.availableCities)
        }
    }, [availableCities, setCities])

    useEffect(() => {
        if (cities.length === 1 && selectedCity.length === 0) {
            setSelectedCity(cities[0])
        } else if (cities.length > 1) {
            setFocusedField(AvailabilityCheckField.CITY)
        }
    }, [cities, setFocusedField, setSelectedCity])

    useEffect(() => {
        if (zip !== '' && selectedCity !== '' && enableAddressDistrict) {
            getAvailableDistricts({
                variables: {
                    zipcode: zip,
                    city: selectedCity,
                },
            })
        }
    }, [selectedCity, getAvailableDistricts, zip])

    useEffect(() => {
        if (districts.length === 1) {
            setSelectedDistrict(districts[0])
        } else if (districts.length > 1) {
            setFocusedField(AvailabilityCheckField.DISTRICT)
        }
    }, [districts, setFocusedField, setSelectedDistrict])

    useEffect(() => {
        if (availableDistricts.data) {
            setDistricts(availableDistricts.data.availableDistricts)
        }
    }, [availableDistricts, setStreets])

    useEffect(() => {
        if (zip != '' && selectedCity !== '') {
            getAvailableStreets({
                variables: {
                    zipcode: zip,
                    city: selectedCity,
                    district: selectedDistrict,
                },
            })
        }
    }, [selectedCity, getAvailableStreets, zip, selectedDistrict])

    useEffect(() => {
        if (availableStreets.data) {
            setStreets(availableStreets.data.availableStreets)
        }
    }, [availableStreets, setStreets])

    useEffect(() => {
        if (streets.length === 1) {
            setSelectedStreet(streets[0])
        } else if (streets.length > 1) {
            setFocusedField(AvailabilityCheckField.STREET)
        }
    }, [streets, setFocusedField, setSelectedStreet])

    useEffect(() => {
        if (selectedStreet !== '' && zip !== '' && selectedCity !== '') {
            getAvailableHouseNumbers({
                variables: {
                    zipcode: zip,
                    city: selectedCity,
                    street: selectedStreet,
                    district: selectedDistrict,
                },
            })
        }
    }, [selectedStreet, getAvailableHouseNumbers, zip, selectedCity, selectedDistrict])

    useEffect(() => {
        if (availableHouseNumbers.data) {
            setHouseNumbers(availableHouseNumbers.data.availableHouseNumbers)
        }
    }, [availableHouseNumbers, setHouseNumbers, setSelectedHouseNumber])

    useEffect(() => {
        if (houseNumbers.length === 1) {
            setSelectedHouseNumber(houseNumbers[0])
            setFocusedField(AvailabilityCheckField.SUBMIT)
        } else if (houseNumbers.length > 1) {
            setFocusedField(AvailabilityCheckField.HOUSE_NUMBER)
        }
    }, [houseNumbers, setFocusedField, setSelectedHouseNumber])

    useEffect(() => {
        if (selectedHouseNumber !== '') {
            setFocusedField(AvailabilityCheckField.SUBMIT)
        }
    }, [selectedHouseNumber, setFocusedField])

    useEffect(() => {
        if (selectedHouseNumber !== '' && enableAddressAddition && zip !== '' && selectedCity !== '') {
            getAvailableAdditions({
                variables: {
                    zipcode: zip,
                    city: selectedCity,
                    street: selectedStreet,
                    district: selectedDistrict,
                    houseNumber: selectedHouseNumber,
                },
            })
        }
    }, [selectedStreet, getAvailableAdditions, zip, selectedCity, selectedDistrict, selectedHouseNumber])

    useEffect(() => {
        if (availableAddition.data) {
            setAdditions(availableAddition.data.availableAdditions)
        }
    }, [availableAddition, setStreets])

    useEffect(() => {
        if (additions.length === 1) {
            setSelectedAddition(additions[0])
        } else if (additions.length > 1) {
            setFocusedField(AvailabilityCheckField.ADDITION)
        }
    }, [additions, setFocusedField, setSelectedAddition])

    useEffect(() => {
        if (focusedFieldId === AvailabilityCheckField.SUBMIT && availabilitySubmitRef.current !== null) {
            availabilitySubmitRef.current.focus()
        }
    }, [focusedFieldId])

    const noProductLink = useMemo(() => {
        if (customizeJsData) {
            return customizeJsData.noProductAvailable.link
        } else {
            return ''
        }
    }, [customizeJsData])

    const [userInfo] = useUserInfo()
    let disabledSubmit = selectedHouseNumber === ''
    let submitButtonText = undefined
    if (!customizeJsData?.globalConfiguration.vzfCheckOwnButton) {
        disabledSubmit = vzf.length === 0 && selectedHouseNumber === ''
        submitButtonText = vzf.length > 0 ? t('vzf.vzfCheck') : undefined
    }

    return (
        <ViewWrapper
            disableBasket={true}
            submitButtonText={submitButtonText}
            disabledSubmit={disabledSubmit}
            submitRef={availabilitySubmitRef}
            header={getRoleAwareTranslationKey(userInfo, customizeJsData, 'availabilityCheckHeader')}
            subHeader={''}
            viewType={currentView}
            disableBackButton={true}
            onSubmitAlt={vzf.length > 0 ? onClickCheckVZF : undefined}
            enableVZFInput={(!B2B && customizeJsData?.globalConfiguration.vzfCheckOwnButton) ?? false}
            vzf={vzf}
            vzfNoResult={vzfNoResult}
            vzfLoading={vzfLoading}
            onClickCheckVZF={onClickCheckVZF}
            onChangeVZF={onChangeVZF}
        >
            <>
                <Box pb={2}>
                    <Typography
                        {...getTestIDOnViewType(ViewType.AVAILABILITY_CHECK, 'SUBHEADER_TYPOGRAPHY')}
                        variant={'h4'}
                    >
                        {t(getRoleAwareTranslationKey(userInfo, customizeJsData, 'availabilityCheckSubheader'))}{' '}
                    </Typography>
                </Box>
                <AvailabilityCheckInput
                    enableAddressAddition={enableAddressAddition}
                    enableAddressDistrict={enableAddressDistrict}
                    haveLink={customizeJsData?.noProductAvailable.haveLink}
                    noProductLink={noProductLink}
                    disabledCity={cities.length < 2}
                    disabledHouseNumber={houseNumbers.length < 2}
                    disabledStreet={streets.length < 2}
                    disabledDistrict={districts.length < 2}
                    disabledAddition={additions.length < 2}
                    disabledZip={false}
                    cities={cities}
                    focusedFieldId={focusedFieldId}
                    houseNumbers={houseNumbers}
                    additions={additions}
                    districts={districts}
                    queriesAreFinished={
                        availableCities.loading === false &&
                        availableCities.called === true &&
                        (availableCities.data !== undefined || availableCities.error !== undefined) &&
                        availableStreets.loading === false &&
                        availableStreets.called === true &&
                        (availableStreets.data !== undefined || availableStreets.error !== undefined) &&
                        availableHouseNumbers.loading === false &&
                        availableHouseNumbers.called === true &&
                        (availableHouseNumbers.data !== undefined || availableHouseNumbers.error !== undefined) &&
                        availableAddition.loading === false &&
                        availableAddition.called === true &&
                        (availableAddition.data !== undefined || availableAddition.error !== undefined) &&
                        availableDistricts.loading === false &&
                        availableDistricts.called === true &&
                        (availableDistricts.data !== undefined || availableDistricts.error !== undefined)
                    }
                    selectedCity={selectedCity}
                    selectedHouseNumber={selectedHouseNumber}
                    selectedStreet={selectedStreet}
                    selectedAddition={selectedAddition}
                    selectedDistrict={selectedDistrict}
                    setFocusedField={setFocusedField}
                    setSelectedCity={(
                        payload: React.ChangeEvent<{
                            name?: string | undefined
                            value: unknown
                        }>,
                    ): void => {
                        setSelectedCity(payload.target.value as string)
                        setSelectedStreet('')
                        setStreets([])
                        setSelectedHouseNumber('')
                        setHouseNumbers([])
                        getAvailableStreets()
                        setAdditions([])
                        setSelectedAddition('')
                        setDistricts([])
                        setSelectedDistrict('')
                    }}
                    setSelectedHouseNumber={(
                        payload: React.ChangeEvent<{
                            name?: string | undefined
                            value: unknown
                        }>,
                    ): void => {
                        setAdditions([])
                        setSelectedAddition('')
                        setSelectedHouseNumber(payload.target.value as string)
                    }}
                    setSelectedStreet={(
                        payload: React.ChangeEvent<{
                            name?: string | undefined
                            value: unknown
                        }>,
                    ): void => {
                        setSelectedStreet(payload.target.value as string)
                        setSelectedHouseNumber('')
                        setHouseNumbers([])
                        setAdditions([])
                        setSelectedAddition('')
                    }}
                    setZip={(
                        payload: React.ChangeEvent<{
                            name?: string | undefined
                            value: unknown
                        }>,
                    ): void => {
                        setSelectedCity('')
                        setCities([])
                        setSelectedStreet('')
                        setStreets([])
                        setSelectedHouseNumber('')
                        setHouseNumbers([])
                        setAdditions([])
                        setSelectedAddition('')
                        setDistricts([])
                        setSelectedDistrict('')
                        setZip(payload.target.value as string)
                        if ((payload.target.value as string).length === 5) {
                            setLoadState({
                                loading: true,
                                errors: undefined,
                            })
                            getAvailableCities({ variables: { zipcode: payload.target.value as string } })
                        }
                    }}
                    setSelectedDistrict={(
                        payload: React.ChangeEvent<{
                            name?: string | undefined
                            value: unknown
                        }>,
                    ): void => {
                        setSelectedStreet('')
                        setStreets([])
                        setSelectedHouseNumber('')
                        setHouseNumbers([])
                        setAdditions([])
                        setSelectedAddition('')
                        setSelectedDistrict(payload.target.value as string)
                    }}
                    setSelectedAddition={(
                        payload: React.ChangeEvent<{
                            name?: string | undefined
                            value: unknown
                        }>,
                    ): void => {
                        setSelectedAddition(payload.target.value as string)
                    }}
                    streets={streets}
                    zip={zip}
                    autoFocusOnMount={true}
                />
                {!B2B && !customizeJsData?.globalConfiguration.vzfCheckOwnButton && (
                    <VZFInput
                        vzf={vzf}
                        loading={vzfLoading}
                        onEnter={onClickCheckVZF}
                        noResult={vzfNoResult}
                        onChangeVZF={onChangeVZF}
                    />
                )}
            </>
        </ViewWrapper>
    )
}

export default AvailabilityCheck
