import { Client, Customize, ProductCategory, VoucherData } from '../../graphql/types'
import {
    GeneralState,
    InstallationDetailsState,
    LoadState,
    OnEditClickTypes,
    OptionType,
    Page,
    ViewType,
} from './GeneralState.reducer'

export enum GeneralStateAction {
    ADD_ERROR_TO_ERROR_CATEGORY = 'ADD_ERROR_TO_ERROR_CATEGORY',
    CLEAR_ERRORS = 'CLEAR_ERRORS',
    CLEAR_ERROR_CATEGORY = 'CLEAR_ERROR_CATEGORY',
    DEFAULT = 'DEFAULT',
    NO_PRODUCTS = 'NO_PRODUCTS',
    REMOVE_ERROR_FROM_ERROR_CATEGORY = 'REMOVE_ERROR_FROM_ERROR_CATEGORY',
    RESET_GENERAL_STATE = 'RESET_GENERAL_STATE',
    SELECT_PRODUCT = 'SELECT_PRODUCT',
    SELECT_PRODUCT_CATEGORY = 'SET_PRODUCT_CATEGORY',
    SELECT_PRODUCT_TYPE = 'SELECT_PRODUCT_TYPE',
    SELECT_PRODUCT_OPTION = 'SELECT_PRODUCT_OPTION',
    SET_AVAILABLE_PRODUCT_CATEGORIES = 'SET_AVAILABLE_PRODUCT_CATEGORIES',
    SET_CONFIG_VALUE = 'SET_CONFIG_VALUE',
    SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
    SET_ENTIRE_CONFIG = 'SET_ENTIRE_CONFIG',
    SET_CUSTOMIZE_DATA = 'SET_CUSTOMIZE_DATA',
    UPDATE_CUSTOMIZE_DATA = 'UPDATE_CUSTOMIZE_DATA',
    SET_DESIRED_DATE = 'SET_DESIRED_DATE',
    SET_EARLIEST_DATE_POSSIBLE = 'SET_EARLIEST_DATE_POSSIBLE',
    SET_REDEEM_MANUAL_VOUCHER = 'SET_REDEEM_MANUAL_VOUCHER',
    SET_VOUCHER_CODE = 'SET_VOUCHER_CODE',
    SET_GENERAL_STATE = 'SET_GENERAL_STATE',
    SET_TELEPHONE_DETAILS = 'SET_TELEPHONE_DETAILS',
    SET_INSTALLATION_DETAILS = 'SET_INSTALLATION_DETAILS',
    SET_LOAD_STATE = 'SET_LOAD_STATE',
    SET_PAGES_LIST = 'SET_PAGES_LIST',
    SET_REQUIRED_TELEPHONE_FIELD = 'SET_REQUIRED_TELEPHONE_FIELD',
    SET_TO_INIT_CONFIG_STATE = 'SET_TO_INIT_CONFIG_STATE',
    TOGGLE_CONFIG_VALUE = 'TOGGLE_CONFIG_VALUE',
    SET_INLINE_ERRORS = 'SET_INLINE_ERRORS',
    SET_GENERAL_STATE_PARTIAL = 'SET_GENERAL_STATE_PARTIAL',
    SET_TO_INIT_STATE = 'SET_GENERAL_STATE_TO_INIT',
    SET_PRODUCT_OPTION_MULTIPLE = 'SET_PRODUCT_OPTION_MULTIPLE',
    ADD_VOUCHERS = 'ADD_VOUCHERS',
    REMOVE_ALL_VOUCHERS = 'REMOVE_ALL_VOUCHERS',
    UPDATE_PAGELIST = 'UPDATE_PAGELIST',
    SET_VFZMAIL = 'SET_VFZMAIL',
    SET_ORDERID = 'SET_ORDERID',
    SET_EDIT_TYPE = 'SET_EDIT_TYPE',
    SET_ON_BACK = 'SET_ON_BACK',
    SELECT_CROSS_SELL_PRODUCT = 'SELECT_CROSS_SELL_PRODUCT',
    SET_VPP_PROCESS_INFO = 'SET_VPP_PROCESS_INFO',
    SET_ORDER_PROCESS_TYPE = 'SET_ORDER_PROCESS_TYPE',
    SET_CLIENT_DATA = 'SET_CLIENT_DATA',
    SET_CONTRACT_START_DATE = 'SET_CONTRACT_START_DATE',
    SET_SUPPLY_START = 'SET_SUPPLY_START',
}

export type ConfigPayload = {
    identifier: string
    value: string
}

type GeneralStateActions =
    | { type: GeneralStateAction.DEFAULT }
    | { payload: { id: string; B2B: boolean }; type: GeneralStateAction.SELECT_PRODUCT_CATEGORY }
    | { payload: { B2B: boolean }; type: GeneralStateAction.SET_TO_INIT_STATE }
    | { payload: Partial<GeneralState>; type: GeneralStateAction.SET_GENERAL_STATE_PARTIAL }
    | { payload: boolean; type: GeneralStateAction.NO_PRODUCTS }
    | {
          payload: { productCategoryId: string; productId: string; B2B: boolean }
          type: GeneralStateAction.SELECT_PRODUCT
      }
    | {
          payload: {
              productCategoryId: string
              productId: string
              productTypeId: string
              optionCategoryId: string
              optionId: string
              typeOfOptionCategory: OptionType
              viewType: string
              B2B: boolean
          }
          type: GeneralStateAction.SELECT_PRODUCT_OPTION
      }
    | {
          payload: {
              productCategoryId: string
              productId: string
              productTypeId: string
              B2B: boolean
          }
          type: GeneralStateAction.SELECT_PRODUCT_TYPE
      }
    | { payload: { list: ProductCategory[]; B2B: boolean }; type: GeneralStateAction.SET_AVAILABLE_PRODUCT_CATEGORIES }
    | { payload: { Customize: Customize; B2B: boolean }; type: GeneralStateAction.SET_CUSTOMIZE_DATA }
    | { payload: { useDesiredDate: boolean }; type: GeneralStateAction.UPDATE_CUSTOMIZE_DATA }
    | { payload: Date | null; type: GeneralStateAction.SET_DESIRED_DATE }
    | { payload: LoadState; type: GeneralStateAction.SET_LOAD_STATE }
    | { payload: boolean; type: GeneralStateAction.SET_EARLIEST_DATE_POSSIBLE }
    | { payload: boolean; type: GeneralStateAction.SET_REDEEM_MANUAL_VOUCHER }
    | { payload: string; type: GeneralStateAction.SET_VOUCHER_CODE }
    | { payload: InstallationDetailsState; type: GeneralStateAction.SET_INSTALLATION_DETAILS }
    | { payload: Map<string, string | string[]>; type: GeneralStateAction.SET_TELEPHONE_DETAILS }
    | { payload: string[]; type: GeneralStateAction.SET_REQUIRED_TELEPHONE_FIELD }
    | { payload: number; type: GeneralStateAction.SET_CURRENT_PAGE }
    | { payload: Page; type: GeneralStateAction.SET_PAGES_LIST }
    | { type: GeneralStateAction.RESET_GENERAL_STATE }
    | { payload: ConfigPayload; type: GeneralStateAction.SET_CONFIG_VALUE }
    | { payload: ConfigPayload; type: GeneralStateAction.TOGGLE_CONFIG_VALUE }
    | { payload: null; type: GeneralStateAction.SET_TO_INIT_CONFIG_STATE }
    | { payload: GeneralState; type: GeneralStateAction.SET_GENERAL_STATE }
    | { payload: { category: string | ViewType; error: string }; type: GeneralStateAction.ADD_ERROR_TO_ERROR_CATEGORY }
    | { type: GeneralStateAction.CLEAR_ERRORS }
    | { payload: string | ViewType; type: GeneralStateAction.CLEAR_ERROR_CATEGORY }
    | { payload: string[]; type: GeneralStateAction.SET_INLINE_ERRORS }
    | {
          payload: { category: string | ViewType; error: string }
          type: GeneralStateAction.REMOVE_ERROR_FROM_ERROR_CATEGORY
      }
    | {
          payload: Map<string, string | string[]>
          type: GeneralStateAction.SET_ENTIRE_CONFIG
      }
    | { payload: { id: string; counter: number }; type: GeneralStateAction.SET_PRODUCT_OPTION_MULTIPLE }
    | {
          payload: VoucherData[]
          type: GeneralStateAction.ADD_VOUCHERS
      }
    | {
          type: GeneralStateAction.REMOVE_ALL_VOUCHERS
      }
    | { payload: { B2B: boolean }; type: GeneralStateAction.UPDATE_PAGELIST }
    | { payload: string; type: GeneralStateAction.SET_VFZMAIL }
    | { payload: string; type: GeneralStateAction.SET_ORDERID }
    | { payload: OnEditClickTypes | undefined; type: GeneralStateAction.SET_EDIT_TYPE }
    | { payload: boolean; type: GeneralStateAction.SET_ON_BACK }
    | { payload: string; type: GeneralStateAction.SELECT_CROSS_SELL_PRODUCT }
    | { type: GeneralStateAction.SET_VPP_PROCESS_INFO }
    | { payload: string; type: GeneralStateAction.SET_ORDER_PROCESS_TYPE }
    | { payload: Client | undefined; type: GeneralStateAction.SET_CLIENT_DATA }
    | { payload: Date | null; type: GeneralStateAction.SET_CONTRACT_START_DATE }
    | { payload: number | null; type: GeneralStateAction.SET_SUPPLY_START }
// | { type: GeneralStateAction.TOGGLE_BASKET }

export default GeneralStateActions
