import { Box, Typography } from '@material-ui/core'
import { Client, GlobalConfiguration, InfoBoxItem } from 'graphql/types'
import { useTranslation } from 'react-i18next'

interface InfoBoxProps {
    globalConfiguration: GlobalConfiguration
    currentPath: string
    backgroundColor: string
    data?: Record<string, string | number | undefined>
    clientData: Client | undefined
}

const InfoBox = ({
    globalConfiguration,
    currentPath,
    backgroundColor,
    data,
    clientData,
}: InfoBoxProps): JSX.Element | null => {
    const { t } = useTranslation()

    const relevantConfig = globalConfiguration.infoBox?.find((config): config is InfoBoxItem => {
        if (config && config.path === '/') {
            return currentPath === '/'
        }
        return config !== null && currentPath.includes(config.path)
    })

    if (relevantConfig) {
        return (
            <Box
                width={1}
                height={1}
                marginTop={3}
                flexDirection="column"
                display="flex"
                style={{
                    backgroundColor: backgroundColor,
                    borderRadius: '5px',
                    alignSelf: 'flex-end',
                    padding: '20px',
                }}
            >
                {relevantConfig.messages.map((entry, index) => {
                    if (entry.condition) {
                        if (
                            (entry.condition === 'useDesiredDate' && clientData && clientData.useDesiredDate) ||
                            clientData == undefined
                        ) {
                            return null
                        }
                    }
                    const message = t(entry.message, data)
                    return (
                        <Typography
                            key={index}
                            variant="h5"
                            align="left"
                            dangerouslySetInnerHTML={{ __html: message }}
                        />
                    )
                })}
            </Box>
        )
    } else {
        return null
    }
}

export default InfoBox
