import { useLazyQuery } from '@apollo/client'
import LoadClientData from 'graphql/queries/LoadClientData'
import LoadOrderData from 'graphql/queries/LoadOrderData'
import { Client, ProductCategory, Query, SelectedProduct, VzfData } from 'graphql/types'
import { useCallback, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dispatch } from 'redux'
import AvailabilityCheckActions, { AvailabilityCheckAction } from 'store/AvailabilityCheck/AvailabilityCheck.actions'
import {
    AvailabilityCheckState,
    initialAvailabilityCheckState,
} from 'store/AvailabilityCheck/AvailabilityCheck.reducer'
import BankDetailsActions, { BankDetailsAction } from 'store/BankDetails/BankDetails.actions'
import { BankDetailsState, initialBankDetailsState } from 'store/BankDetails/BankDetails.reducer'
import ContactDataActions, { ContactDataAction } from 'store/ContactData/ContactData.actions'
import { ContactDataState, initialContactDataState } from 'store/ContactData/ContactData.reducer'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import {
    GeneralState,
    LoadState,
    SelectedProductCategory,
    ViewType,
    initialGeneralState,
} from 'store/GeneralState/GeneralState.reducer'
import PortabilityStateActions, { PortabilityStateAction } from 'store/PortabilityState/PortabilityState.actions'
import { PortabilityState, initialPortabilityState } from 'store/PortabilityState/PortabilityState.reducer'
import { AppState } from 'store/store'
import useURLParams from 'utils/URLParamsContex'
import { convertOrderDataToState } from 'utils/convertOrderDataToState'
import { decryption } from 'utils/crypto'
import { OrderDataLoadProps } from './OrderDataLoad'
interface UseOrderDataLoadReturn {
    loadState: LoadState
}

const useOrderDataLoad: (props: OrderDataLoadProps) => UseOrderDataLoadReturn = (props: OrderDataLoadProps) => {
    const { id, token } = props
    const history = useHistory()
    const { B2B } = useURLParams()
    const dispatch =
        useDispatch<
            Dispatch<
                | AvailabilityCheckActions
                | BankDetailsActions
                | ContactDataActions
                | GeneralStateActions
                | PortabilityStateActions
            >
        >()

    const { loadState, customizeJsData } = useSelector((appState: AppState) => ({
        loadState: appState.generalState.loadState,
        customizeJsData: appState.generalState.customizeJsData,
    }))

    const setLoadState = useCallback(
        (payload: LoadState): void => {
            dispatch({ type: GeneralStateAction.SET_LOAD_STATE, payload })
        },
        [dispatch],
    )

    const setAvailabilityCheck = useCallback(
        (payload: AvailabilityCheckState): void => {
            dispatch({ type: AvailabilityCheckAction.SET_AVAILABILITY_STATE, payload })
        },
        [dispatch],
    )

    const setGeneralState = useCallback(
        (payload: GeneralState): void => {
            dispatch({ type: GeneralStateAction.SET_GENERAL_STATE, payload })
        },
        [dispatch],
    )

    const updatePageList = useCallback(
        (payload: { B2B: boolean }): void => {
            dispatch({ type: GeneralStateAction.UPDATE_PAGELIST, payload })
        },
        [dispatch],
    )

    const setContactDataState = useCallback(
        (payload: ContactDataState): void => {
            dispatch({ type: ContactDataAction.SET_CONTACT_DATA_STATE_PARTIAL, payload })
        },
        [dispatch],
    )

    const setBankDetailsState = useCallback(
        (payload: BankDetailsState): void => {
            dispatch({ type: BankDetailsAction.SET_BANK_DETAILS_STATE, payload })
        },
        [dispatch],
    )

    const setClientData = useCallback(
        (payload: Client | undefined) => {
            dispatch({ type: GeneralStateAction.SET_CLIENT_DATA, payload })
        },
        [dispatch],
    )

    const setPortabilityState = useCallback(
        (payload: PortabilityState): void => {
            dispatch({ type: PortabilityStateAction.SET_PORTABILITY_STATE, payload })
        },
        [dispatch],
    )

    const [loadClientData] = useLazyQuery<Query>(LoadClientData, {
        fetchPolicy: 'network-only',
        onCompleted: (data): void => {
            if (data.loadClientData) {
                setLoadState({
                    ...loadState,
                    loading: false,
                })
                setClientData(data.loadClientData)
                updatePageList({ B2B })
                history.push('/Kontaktdaten')
            } else {
                setGeneralState(initialGeneralState)
                setAvailabilityCheck(initialAvailabilityCheckState)
                setContactDataState(initialContactDataState)
                setBankDetailsState(initialBankDetailsState)
                setPortabilityState(initialPortabilityState)
                setLoadState({
                    ...loadState,
                    loading: false,
                })
                history.push(initialGeneralState.pagesList[0].path)
            }
        },
    })

    const [loadOrderData] = useLazyQuery<Query>(LoadOrderData, {
        fetchPolicy: 'network-only',
        onCompleted: (data): void => {
            if (data.loadOrderProgress) {
                // @ts-expect-error: init the previousProvider
                const previousProvider: string[] = window.previousProvider

                const vzfData: VzfData = data.loadOrderProgress.vzfData

                const availableProductCategories: ProductCategory[] = JSON.parse(vzfData.selectedProductCategories)

                // translate to selected structure
                const selectedProductCategories: SelectedProductCategory[] = availableProductCategories.map(
                    (productCategory) => {
                        let selectedProduct: SelectedProduct | null = null
                        const productData = productCategory.products[0]
                        if (productData) {
                            selectedProduct = {
                                id: productData.id,
                                productTypes: productData.productTypes.map((productType) => ({
                                    id: productType.id,
                                    optionCategories: productType.category.map((optionCategory) => ({
                                        id: optionCategory.id,
                                        selectedOptions: optionCategory.options.map((option) => option.id),
                                    })),
                                })),
                            }
                        }

                        return { id: productCategory.id, selectedProduct }
                    },
                )

                const optionsMultipleSelect = new Map<string, number>()
                vzfData.multipleSelectOptionList.forEach((entry) => {
                    optionsMultipleSelect.set(entry.key, entry.value)
                })

                decryption(data.loadOrderProgress.data, token).then((orderDataInput) => {
                    const newAppState = convertOrderDataToState(orderDataInput)
                    newAppState.portabilityState.providerOptions = previousProvider
                    setGeneralState({
                        ...newAppState.generalState,
                        vzfID: data.loadOrderProgress?.vzfID ?? '',
                        availableProductCategories: availableProductCategories,
                        selectedProductCategories: selectedProductCategories,
                        startOfDelivery: vzfData.startOfDelivery ?? undefined,
                        startOfMarketing: vzfData.startOfMarketing ?? undefined,
                        optionsMultipleSelect,
                        currentView: ViewType.ORDER_LOAD,
                        customizeJsData: customizeJsData,
                    })
                    setAvailabilityCheck({
                        ...newAppState.availabilityCheck,
                        selectedCity: vzfData.city,
                        selectedStreet: vzfData.street,
                        selectedHouseNumber: vzfData.houseNumber,
                        zip: vzfData.zipcode,
                    })
                    setContactDataState(newAppState.contactData)
                    setBankDetailsState(newAppState.bankDetails)
                    setPortabilityState(newAppState.portabilityState)

                    if (
                        customizeJsData &&
                        customizeJsData.globalConfiguration.enableLoadingClient &&
                        orderDataInput.clientID
                    ) {
                        loadClientData({
                            variables: {
                                id: orderDataInput.clientID.toString(),
                            },
                        })
                    } else {
                        setLoadState({
                            ...loadState,
                            loading: false,
                        })
                        updatePageList({ B2B })
                        history.push('/Kontaktdaten')
                    }
                })
            } else {
                setGeneralState(initialGeneralState)
                setAvailabilityCheck(initialAvailabilityCheckState)
                setContactDataState(initialContactDataState)
                setBankDetailsState(initialBankDetailsState)
                setPortabilityState(initialPortabilityState)
                setLoadState({
                    ...loadState,
                    loading: false,
                })
                history.push(initialGeneralState.pagesList[0].path)
            }
        },
    })

    useLayoutEffect(() => {
        setLoadState({
            loading: true,
        })
        loadOrderData({
            variables: {
                id,
            },
        })
    }, [])

    return {
        loadState,
    }
}

export default useOrderDataLoad
